import { createContext, useContext } from "solid-js";
import { MetaContextActions } from "./model";
import { SHARED_MODEL } from ":mods";
// export const $cookie = service.state.create<Cookie>(cookies.get(COOKIE_NAME));

export const MetaContext = createContext<MetaContextActions>();
export const AppContext = createContext<SHARED_MODEL.AppFlavor>();

export const hooks = {
	useMeta: () => useContext(MetaContext),
	useAppFlavor: () => useContext(AppContext),
};
